export enum ApiActionModifier {
	ACTIVE = 'active',
	ALL = 'all',
	BILLING_USERS = 'billing-users',
	BY_EMAIL = 'by-email',
	BY_FILE = 'by-file',
	BY_FOLDER = 'by-folder',
	BY_ID = 'by-id',
	BY_ORIGINAL_NAME = 'by-original-name',
	BY_PROJECT = 'by-project',
	BY_PROJECT_ID = 'by-projectId',
	BY_PROJECT_TREE = 'by-project-tree',
	DEFAULTS = 'defaults',
	DELIVERY_NOTE = 'delivery-note',
	DETAILS = 'details',
	FILES = 'files',
	SIMPLE = 'simple',
	SUGGEST_EMAIL = 'suggest-email',
	TO_PROJECT = 'to-project',
	ZIP = 'zip',
}
